import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';
import {AuthResponse} from "../models/response/AuthResponse"; //new
import {IUser} from "../models/IUser"; // new
import AuthService from "../services/AuthService"; //new

/*interface User {
  _id: string;
  name: string;
  username: string;
}*/

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  username: string; 
  password: string;
}

interface AuthContextData {
  //user: User;
  user:IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ChatFormare:token');
    const user = localStorage.getItem('@ChatFormare:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) }; // parser to be reviewed
    }

    return {} as AuthState;
  });

 /* const signIn = useCallback(async ({ username, password }) => {
    const response = await api.post('happ/sessions', {
      username,
      password,
    });*/
    const signIn = useCallback(async ({ username, password }) => {
     // try {
        const response = await AuthService.login(username, password);
        console.log(response)
        //localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('@ChatFormare:token', response.data.accessToken);
        localStorage.setItem('@ChatFormare:user', JSON.stringify(response.data.user));
        api.defaults.headers.authorization = `Bearer ${response.data.accessToken}`;
        setData({ token: response.data.accessToken, user: response.data.user});
       // this.setAuth(true);
        //this.setUser(response.data.user);
   // } catch (e: any) {
   //     console.log(e.response?.data?.message);            
   // }

    
   // const { token, user } = response.data;

   // localStorage.setItem('@ChatFormare:token', token);
    //localStorage.setItem('@ChatFormare:user', JSON.stringify(user));

    //api.defaults.headers.authorization = `Bearer ${token}`;

   // setData({ token, user });
  }
  
  
  , []);

  const signOut = useCallback(async () => {

//  try {
    const response = await AuthService.logout();
    console.log("logou status:", response);
    localStorage.removeItem('@ChatFormare:token');
    localStorage.removeItem('@ChatFormare:user');
    setData({} as AuthState);
  //} catch (e:any) {
    //console.log(e.response?.data?.message);
//}
   /* await api.put('status', {
      newStatus: 'offline',
    });

    localStorage.removeItem('@ChatFormare:token');
    localStorage.removeItem('@ChatFormare:user');

    setData({} as AuthState);*/
  }, []);

  /**to be added */
/*  async checkAuth() {
    this.setLoading(true);
    try {
        const response = await axios.get<AuthResponse>(`${API_URL}/refresh`, {withCredentials: true})
        console.log(response);
        localStorage.setItem('token', response.data.accessToken);
        this.setAuth(true);
        this.setUser(response.data.user);
    } catch (e:any) {
        console.log(e.response?.data?.message);
    } finally {
        this.setLoading(false);
    }
}*/

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
