import api from "./api";
import {AxiosResponse} from 'axios';
import { AuthResponse } from "../models/response/AuthResponse";

export default class AuthService{
    static async login(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return api.post<AuthResponse>('/login', {username, password})
    }

    static async registration(username: string, password: string): Promise<AxiosResponse<AuthResponse>> {
        return api.post<AuthResponse>('/registration', {username, password})
    }

    static async logout(): Promise<void> {
        return api.post('/logout')
    }

    static async survey( content: string): Promise<AxiosResponse<AuthResponse>> {
        return api.post<AuthResponse>('/survey', { content})
    } //new
    
}