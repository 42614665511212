import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LSpinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 3px solid #7ddb80;
  border-right: 3px solid #7ddb80;
  border-bottom: 3px solid #7ddb80;
  border-left: 3px solid #f5f5f5;
  background: transparent;
  width: 32px;
  height: 32px;
  border-radius: 80%;
`;

export function LoadingSpinner() {

  return (
    <Container>
         <LSpinner />
         </Container>
  );

}

