import React, {  useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
//import "./index.css";
import { json } from "../../components/json";
//import { Message } from "../../components/Chat/styles";
import { useAuth } from "../../hooks/auth";
import AuthService from "../../services/AuthService";
//import Socket from '../../services/socket';
//import { parseISO } from 'date-fns';


//interface Scheme {
  //  _id: string;
  //  content: string;
  
  //}


function SurveyComponent() {
    
   // const [messages, setMessages] = useState<Scheme[]>([]);
    const [newSurvey, setNewSurvey] = useState('');
    const [newResdata, setResdata] = useState('');
    const { user: userAuth } = useAuth();
    //const socket = Socket({ user_id: userAuth._id });
   
    const survey = new Model(json);
    survey.onComplete.add(async (sender, options) => {
        //console.log(JSON.stringify(sender.data, null, 3));
    
        setNewSurvey(JSON.stringify(sender.data, null, 3),
        
        )
     // await api.post('chat', {
         //  content: JSON.stringify(sender.data, null, 3),})
   
    });
   
    console.log(newSurvey);
    //console.log(userAuth._id);
   // setNewJpost({
 //       _id:"6665555",
  //      content:newSurvey,

//})

useEffect(() => {
    // Return early, if this is the first render:
   async function ApiCall() {
    try {
    const response = await AuthService.survey( newSurvey);
    //console.log(response)

    if(response){
      window.alert(`data has been submitted succesfuly ${userAuth.username}♡,   you will be redirect to upload some photos`)
    }
    }

    catch(err)
    {
       console.log(err);
     // window.alert(`oops something want wrong ${err}`);
    }
    // console.log(response);
     //localStorage.setItem('@ChatFormare:token', response.data.accessToken);
     //localStorage.setItem('@ChatFormare:user', JSON.stringify(response.data.user));
     //api.defaults.headers.authorization = `Bearer ${response.data.accessToken}`;
    
   /** try {
        await api.post('survey', {
          user_id: userAuth.username,
          content: newSurvey,
        });
      }
      catch(err)
      {
          window.alert(`oops something want wrong ${err}`);
      }*/
    }
       // setNewSurvey('');   
     ApiCall();
  }, [newSurvey, userAuth.username]);
   /* const handleSendNewMessage = useCallback(
        async (event:Model) => {
       
            event?.preventDefault();
          console.log("from callback",newSurvey);
          try {
          await api.post('survey', {
            user_id: userAuth.username,
            content: newSurvey,
          });
        }
        catch(err)
        {
            window.alert(`oops something want wrong ${err}`);
        }
         // setNewSurvey('');   
          
        },
        [newSurvey,userAuth.username]
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      handleSendNewMessage;*/

      //setNewSurvey("");


  /* useEffect(() => { 
        socket.on(
            'message',
            (newMessage: Omit<{ message: Scheme }, 'formattedDate'>) => {
              setMessages([
                ...messages,
                {
                  _id: newMessage.message._id,
                  user: {
                    user_id: newMessage.message.user.user_id,
                    name: newMessage.message.user.name,
                    username: newMessage.message.user.username,
                  },
                  content:newMessage.message.content,
                  createdAt: newMessage.message.createdAt,
                  formattedDate: parseISO(newMessage.message.createdAt.toString()),
                },
              ] as Scheme[]);
            }
          );
        }, [messages]);*/
        
    return (<Survey model={survey} />);
}

export default SurveyComponent;