import React, { useState, FormEvent, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';

import api from '../../services/api';

import { Container } from './styles';
import AuthService from '../../services/AuthService';
import  {LoadingSpinner}  from "../../common/LoadingSpinner"


const SignUp: React.FC = () => {
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading]   = useState(false);

  const history = useHistory();

  const Spinner = () =>{
   
    if (loading)
      {return <LoadingSpinner/>;}
        
      }

  const handleSubmit = useCallback(
    

    async (event: FormEvent) => {
      event?.preventDefault();
      setLoading(true);
      try {
        const response = await AuthService.registration(username, password);
       console.log(response.status);
        localStorage.setItem('@ChatFormare:token', response.data.accessToken);
        localStorage.setItem('@ChatFormare:user', JSON.stringify(response.data.user));
        api.defaults.headers.authorization = `Bearer ${response.data.accessToken}`;
        history.push('/Home');
   } catch (error){
    alert("can not signed up at the moment");
   };
   setLoading(false);
        
      //if(!(e)) { throw e; }
      // code to handle ValidationError
      //console.log(e.response?.data?.message);
    //}
             
   /*   await api.post('/registration', {
        username, //email
        password,
      });*/

     // history.push('/Home');
    },
    [history, password, username]

  );
 
 
  return (
    <Container>
     {!loading && <><form data-testid='login-form' onSubmit={handleSubmit}>
        <h2>Register</h2>
        <input
          type='text'
          placeholder='name'
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <input
          type='text'
          placeholder='username'
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <input
          type='password'
          placeholder='password'
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <button type='submit'>enter</button>
      </form>
      <Link to='/'>I already have an account</Link>
      </>}
      {Spinner()}
    </Container>
  );
};

export default SignUp;
