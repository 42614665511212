import React, { useState, FormEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Container } from './styles';
import  {LoadingSpinner}  from "../../common/LoadingSpinner"


const SignIn: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading]   = useState(false);

  const { signIn } = useAuth();

  const handleSubmit = useCallback( 
    async (event: FormEvent) => {
      event?.preventDefault();
      setLoading(true);
 try { 
     await signIn({ username, password }); //username
    
} catch(error)
{
  console.log('signIn response:', error)
}
setLoading(false);
    },
  


    [password, signIn, username]
  );
  const Spinner = () =>{
   
    if (loading)
      {return <LoadingSpinner/>;}
        
      }

  return (
    <>
 <Container>
    {!loading && <> <form data-testid='login-form' onSubmit={handleSubmit}>
        <h2>Login</h2>
        <input
          type='text'
          placeholder='username'
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
        <input
          type='password'
          placeholder='password'
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <button type='submit'>submit</button>
      </form>
      <Link to='/signup'>sign me up</Link>
      </>}
      {Spinner()}
    </Container>
    </>
  );
 
};

export default SignIn;
