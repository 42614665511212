import React from 'react';

import Header from '../../components/Header';
//import UsersList from '../../components/UsersList';



import { Container, Content } from './styles';
import SurveyComponent from '../SurveyComponent';

const Home: React.FC = () => {
  return (
    <Container>
      <Header />
      <Content>
        <SurveyComponent />
      </Content>
    </Container>
  );
};

export default Home;
